import * as React from "react";
import PageLayout from "../components/PageLayout";
import { StaticImage } from "gatsby-plugin-image";

// markup
const WashedVisualArchetype = () => {
  return (
    <PageLayout>
      <div className="center">
        <div className="content">
          <h1 className="title">
            Washed Visual: <strong>Archetype</strong>
          </h1>
          <div className="image">
            <StaticImage src="../images/archetype-placeholder-min.jpg" alt="Whale Adam Bombs" layout="constrained" width={400} />
          </div>
          <div className="description">
            <p>Archetype is an experiment in character evolution that demonstrates how simple changes in lighting and color can alter the mood of a visual element. The collection of masks was created by CG artist Tim Francisco, and will serve as the genesis NFT collection for Washed Visual (@washedvisual). All archetype holders will receive special benefits within the Washed Visual ecosystem.</p>
          </div>
        </div>
      </div>
      <hashku-mint api-url="https://api.hashku.com/api" team-slug="washed-vusal" project-slug="archetype" contract-address="0x42c866b90ee685Dc375A8177a41af883f2A41Ade" contract-network="1" signup-only="no" winter-url="https://checkout.usewinter.com/?projectId=6874" />
    </PageLayout>
  );
};

export default WashedVisualArchetype;
